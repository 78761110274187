// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

// semantic-ui
import { Container, Header, Icon } from 'semantic-ui-react'

// services
import { isRegistered } from 'services/self'
import { updateUsagePlansAndApisList, getApi } from 'services/api-catalog'

// components
import ApisMenu from 'components/ApisMenu'
import SwaggerLayoutPlugin from 'components/SwaggerUiLayout'
import PageWithSidebar from 'components/PageWithSidebar'

// state
import { store } from 'services/state.js'
import { observer } from 'mobx-react'

export default observer(class ApisPage extends React.Component {
  containerRef = React.createRef()
  hasRoot = false

  componentDidMount () { this.updateApi(true) }
  componentDidUpdate () { this.updateApi(false) }
  componentWillUnmount () { this.containerRef = null }

  updateApi (isInitial) {
    return getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, isInitial)
      .then(api => {
        if (this.containerRef == null) return
        const elem = this.containerRef.current
        const isFirstLoad = !this.hasRoot

        this.hasRoot = elem != null
        if (api && elem != null) {
          const cell = {
            shouldPreauthorizeApiKey: false,
            preauthorizeApiKey: () => {
              cell.shouldPreauthorizeApiKey = true
            }
          }
          const swaggerUiConfig = {
            domNode: this.containerRef.current,
            plugins: [SwaggerLayoutPlugin],
            supportedSubmitMethods: [],
            spec: api.swagger,
            onComplete: () => cell.preauthorizeApiKey()
          }
          if (isRegistered()) {
            delete swaggerUiConfig.supportedSubmitMethods
          }

          const uiHandler = SwaggerUI(swaggerUiConfig)
          cell.preauthorizeApiKey = () => {
            if (store.apiKey) {
              uiHandler.preauthorizeApiKey('api_key', store.apiKey)
            }
          }
          if (cell.shouldPreauthorizeApiKey) {
            cell.preauthorizeApiKey()
          }

          if (isFirstLoad) return updateUsagePlansAndApisList(true)
        }
      })
  }

  render () {
    let errorHeader
    let errorBody

    if (!store.apiList.loaded || (!store.apiList.apiGateway.length && !store.apiList.generic.length)) {
      errorHeader = 'No APIs published or not logged in'
      errorBody = 'You are not logged in or your administrator hasn\'t added any APIs to your account.'
      return (
        <div className='swagger-ui-wrap' ref={this.containerRef} style={{ padding: '0 20px' }}><Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}><Icon name='warning sign' circular /><Header.Content>{errorHeader}</Header.Content></Header><Container text textAlign='justified'><p>{errorBody}</p><p>If you want to just browse the API (latest version) without making authorised requests, you can visit: <a href='https://swagger.myswitzerland.io' target='_blank'>swagger.myswitzerland.io</a></p></Container></div>
      )
    } else if (!store.api) {
      errorHeader = 'No Such API'
      errorBody = 'The selected API doesn\'t exist.'
      return (
        <PageWithSidebar sidebarContent={<ApisMenu path={this.props.match} activateFirst={true} />} SidebarPusherProps={{ className: 'swagger-section' }}><div className='swagger-ui-wrap' ref={this.containerRef} style={{ padding: '0 20px' }}><Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}><Icon name='warning sign' circular /><Header.Content>{errorHeader}</Header.Content></Header><Container text textAlign='justified'><p>{errorBody}</p></Container></div></PageWithSidebar>
      )
    } else {
      return (
        <PageWithSidebar sidebarContent={<ApisMenu path={this.props.match} activateFirst={true} />} SidebarPusherProps={{ className: 'swagger-section' }}><div className='swagger-ui-wrap' ref={this.containerRef} style={{ padding: '0 20px' }}></div></PageWithSidebar>
      )
    }
  }
})
